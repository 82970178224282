<template>
  <div class="audience-selector">
    <v-combobox
      v-model="input"
      :items="items"
      :search-input.sync="search"
      @input="selectItem"
      @keyup.enter="selectItem"
      @focus="listGroupAudiences"
      ref="combobox"
      :placeholder="$t('audience_selector.placeholder')"
      no-filter
    >
      <template slot="no-data">
        <div class="px-3 py-2 sw-caption grey--text">
          <div v-if="isLoading">{{ $t("audience_selector.searching") }}</div>
          <div v-if="!isLoading">
            {{ $t("audience_selector.nothing_found") }}
          </div>
        </div>
      </template>
      <template slot="item" slot-scope="data">
        {{ data.item.name }}
      </template>
    </v-combobox>
    <v-layout row wrap class="mx-n3">
      <v-flex
        v-for="(audience, i) in model"
        :key="`item-${i}`"
        class="xs4 py-2 px-3"
      >
        <v-card>
          <v-btn
            fab
            class="ma-0 sw-accent-bg sw-on-accent small-fab"
            @click="removeItem(audience.id)"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-card-text>
            <div class="sw-caption grey--text">Audience</div>
            <div class="text-overflow--ellipsis">{{ audience.name }}</div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isLoading: false,
    groupAudiences: [],
    input: null,
    search: "",
    searchTimeout: null,
    perPage: 20,
  }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    groupId() {
      return this.$route.params.group_id;
    },
    items() {
      if (this.isLoading) return [];

      const selectedIds = this.model.map((el) => el.id);

      return this.groupAudiences.filter((el) => !selectedIds.includes(el.id));
    },
  },
  watch: {
    search() {
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => this.listGroupAudiences(), 500);
    },
  },
  methods: {
    async listGroupAudiences() {
      if (!this.groupId) return;

      try {
        this.isLoading = true;

        const params = {
          per_page: this.perPage,
        };

        if (this.search) {
          params.search = this.search;
        }

        const response = await this.$api.groupAudiences.list(
          this.groupId,
          params,
        );

        this.isLoading = false;

        if (!response || !response.data.data) {
          return;
        }

        this.groupAudiences = response.data.data;
      } catch (error) {
        this.isLoading = false;
      }
    },
    addItem(item) {
      if (!item || !item.id) return;

      this.model.push(item);

      this.$nextTick(() => {
        this.input = "";

        this.$refs.combobox.blur();
      });
    },
    selectItem(item) {
      if (this.isLoading) return;

      if (this.items && this.items.length === 1) {
        this.addItem(this.items[0]);
        return;
      }

      if (!item || !item.id) return;

      this.addItem(item);
    },
    removeItem(id) {
      if (!id) return;
      this.model = this.model.filter((el) => el.id !== id);
    },
  },
};
</script>
