<template>
  <div class="recipient-selector">
    <v-combobox
      v-model="input"
      :items="items"
      :search-input.sync="search"
      @input="selectItem"
      @keyup.enter="selectItem"
      @focus="listRecipients"
      ref="combobox"
      :placeholder="$t('recipient_selector.placeholder')"
      no-filter
    >
      <template slot="no-data">
        <div class="px-3 py-2 sw-caption grey--text">
          <div v-if="isLoading">{{ $t("recipient_selector.searching") }}</div>
          <div v-if="!isLoading">
            {{ $t("recipient_selector.nothing_found") }}
          </div>
        </div>
      </template>
      <template slot="item" slot-scope="data">
        {{ data.item.first_name }} {{ data.item.last_name }}
      </template>
    </v-combobox>
    <v-layout row wrap class="mx-n3">
      <v-flex
        v-for="(user, i) in model"
        :key="`item-${i}`"
        class="xs4 py-2 px-3"
      >
        <v-card>
          <v-btn
            fab
            class="ma-0 sw-accent-bg sw-on-accent small-fab"
            @click="removeItem(user.id)"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-card-text>
            <div class="sw-caption grey--text">Guest</div>
            <div class="text-overflow--ellipsis">
              {{ user.first_name }} {{ user.last_name }}
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isLoading: false,
    groupAdministrators: [],
    groupUsers: [],
    input: "",
    search: "",
    searchTimeout: null,
    perPage: 15,
  }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    groupId() {
      return this.$route.params.group_id;
    },
    items() {
      if (this.isLoading) return [];

      const groupUsers = [];

      for (const user of this.groupUsers) {
        const index = this.groupAdministrators.findIndex(
          (el) => el.id === user.id,
        );

        if (index >= 0) continue;

        groupUsers.push(user);
      }

      const users = [...this.groupAdministrators, ...groupUsers];
      const selectedIds = this.model.map((el) => el.id);

      return users.filter((el) => !selectedIds.includes(el.id));
    },
  },
  watch: {
    search() {
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => this.listRecipients(), 500);
    },
  },
  methods: {
    async listGroupAdministrators() {
      if (!this.groupId) return;

      const params = {
        per_page: this.perPage,
      };

      if (this.search) {
        params.search = this.search;
      }

      const response = await this.$api.groupAdministrators.list(
        this.groupId,
        params,
      );

      if (!response || !response.data.data) {
        return;
      }

      this.groupAdministrators = response.data.data;
    },
    async listGroupUsers() {
      if (!this.groupId) return;

      const params = {
        per_page: this.perPage,
      };

      if (this.search) {
        params.search = this.search;
      }

      const response = await this.$api.groupUsers.list(this.groupId, params);

      if (!response || !response.data.data) {
        return;
      }

      this.groupUsers = response.data.data;
    },
    async listRecipients() {
      try {
        this.isLoading = true;

        await this.listGroupAdministrators();
        await this.listGroupUsers();

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    addItem(item) {
      if (!item || !item.id) return;

      this.model.push(item);

      this.$nextTick(() => {
        this.input = "";

        this.$refs.combobox.blur();
      });
    },
    selectItem(item) {
      if (this.isLoading) return;

      if (this.items && this.items.length === 1) {
        this.addItem(this.items[0]);

        return;
      }

      if (!item || !item.id) return;

      this.addItem(item);
    },
    removeItem(id) {
      if (!id) return;
      this.model = this.model.filter((el) => el.id !== id);
    },
  },
};
</script>
